import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import PropTypes from 'prop-types';
import { withMediaQueries } from 'hoc/withMediaQueries';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { Col, Row } from 'ui/gridSystem';
import { CustomRightArrow, CustomLeftArrow } from 'ui/atoms/CarouselArrows';
import { ContentCardUser, Footer, Spinner } from 'ui/components';

  import { MODAL_OPEN } from 'redux/actions';
import { GET_PACKAGE, GET_PACKAGE_ASSET_DESCRIPTION, SET_PLAYLIST_HISTORY, SET_SECTION } from 'redux/actions/playlist';
  import routes from 'routes';

import ProDisplay from 'ui/typography/proDisplay';
import ProText from 'ui/typography/proText';
import TYPES from 'ui/typography/pro.types';
import PlaceholderImg from 'ui/assets/img/S4C_THUMBNAIL.png';

import { responsiveLargeCards } from 'utils/carousel';
import { secsToTime } from 'utils/duration-format';
import { detailType, unescape } from 'utils/common';

import Pdf from 'ui/atoms/IconFiles/Pdf';
import Excel from 'ui/atoms/IconFiles/Excel';
import Word from 'ui/atoms/IconFiles/Word';
import Powerpoint from 'ui/atoms/IconFiles/Powerpoint';

import MainContainer from '../style';
import {
  PackageHeader,
  Section,
  Container,
  Link,
  PackageProgresss,
  PackageFooterCard, NoMaterials
} from './style';
import IconBigDownload from '../../ui/atoms/IconBigDownload';
import {
  Document,
  Image as ImagePdf,
  Page, PDFDownloadLink, StyleSheet,
  Text as TextPdf,
  View
} from "@react-pdf/renderer";

import SfondoPDF from '../../ui/assets/img/SfondoPDF.jpg';
import {ButtonWrapper, SurveyContainer} from '../CollectionDetails/style';
import {Button, Tooltip} from '../../ui/atoms';
import AssessmentModal from '../../ui/components/Modals/AssessmentModal';

import {
  HeaderWrapper,
  FirstHalfWrapper,
  FirstHalf,
  HeaderIcon,
  Title,
  Text,
  IconInfo,
} from 'pages/CollectionDetails/style';
import InfoModal from '../../ui/components/Modals/InfoModal';
import replaceAsciiWithChar from '../../utils/replaceAsciiWithChar';
import {GET_ECM_CREDITI_ACQUISITI} from '../../redux/actions/ecm';

  const PackageDetails = ({
    // HOC store
    assetDetail,
    packageInfo,
  packageData,
    profile,
    router,
  domainId,
  ecmCreditiAcquisiti,
    // HOC dispatch
    openModal,
    getPackage,
    pushUrl,
    setPlaylistHistory,
    setSection,
    getPackageAssetDescription,
    getEcmCreditiAcquisiti,
    // HOC media queries
    currentViewport,
    viewportTypes,
    company,
  }) => {
    const { id } = useParams();

  useEffect(() => {
    // TODO: prefetch from home?
    getPackage(id);
  }, [id, getPackage])

  const mergeContents = (...contents) => [].concat(...contents.filter(Array.isArray));

  const contents = mergeContents(
      assetDetail.collection?.items,
      assetDetail.event?.items,
      assetDetail.selection?.items
  );

  contents.sort((a, b) => a.weight < b.weight ? -1 : 1);

  const openModalInfoHandler = (card, _type)=>{
    getPackageAssetDescription({...card, itemType: (card.packageType ? 'package' : (_type || "").toLowerCase()), packageId: card.packageType ? card.itemId : '', assetId: !card.packageType ? card.itemId : ''});
  }

  // const parseFraction = (fracString, position=0) => {
  //   if (!fracString) return 0;
  //   return parseInt(fracString.split('/')[position])
  // };
  // const progress = (
  //   (
  //     parseFraction(assetDetail.collection?.progress, 0)
  //     + parseFraction(assetDetail.selection?.progress, 0)
  //     + parseFraction(assetDetail.event?.progress, 0)
  //   ) / (
  //     parseFraction(assetDetail.collection?.progress, 1)
  //     + parseFraction(assetDetail.selection?.progress, 1)
  //     + parseFraction(assetDetail.event?.progress, 1)
  //   )
  // );

  const openDetails = ({ type, itemId }) => {
    if (type === detailType.PLAYLIST) {
      setSection('playlist');
      setPlaylistHistory(router.location.pathname);
      pushUrl(`${routes.detail.path}/${detailType.PLAYLIST}/${itemId}`);
    } else {
      pushUrl(`${routes.event.path}/${itemId}`)
    }
  }

// Create styles
  const stylespdfAttestato = StyleSheet.create({
    attestatoLine1: {
      color: '#d24557',
      textTransform: 'uppercase',
      fontWeight: 800,
      fontSize: '24'
    },
    attestatoLine2: {
      color: '#000',
      textTransform: 'uppercase',
      fontWeight: 900,
      fontSize: '24',
      marginBottom: 20
    },
    page: {
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      display: 'flex',
      position: 'relative',
      fontWeight: 'light'
    },
    section: {
      margin: '0',
      width: '360',
      height: '100%',
      flexGrow: 1,
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      position: 'absolute',
      lineHeight: 1.3,
      top: 0,
      left: 0,
      right: 0,
      marginLeft: '80'
    },
    bgImg: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0
    },
    redLine: {
      width: 80,
      height: 20,
      backgroundColor: '#db4f5f',
      marginBottom: 20
    },
    footer: {
      backgroundColor: '#ffffff',
      height: 70,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      padding: '0 40px'
    },
    logoFooter: {
      height: 50,
      padding: 10
    },
    logoFooterDx: {
      height: 60,
      padding: 10
    },
    textRegular: {
      color: '#000',
      fontSize: 18,
      fontWeight: "regular"
    },
    textDate: {
      color: '#000',
      fontSize: 14,
      fontWeight: "regular"
    },
    nomeCognome: {
      color: '#db4f5f',
      fontSize: 22,
      fontWeight: "bold",
      marginBottom: 20,
    },
    nomeCorso: {
      color: '#db4f5f',
      fontSize: 22,
      fontWeight: "bold",
    },
    textSmall:{
      color: '#000',
      fontSize: 14
    },
    textMedium:{
      color: '#ffffff',
      fontSize: 25
    },
    sectionLeadershipContainer: {
      marginTop: 40,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    sectionLeadership: {
    },
    sectionFirma: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    firma: {
      height: 80
    }
  });

  //NEW PDF STYLE

  const stylespdfCertificato = StyleSheet.create({
    container: {
      margin: '20px 30px 20px 40px',
      paddingTop: 3,
      paddingBottom: 30,
      border: '0.5px solid black',
      display: 'flex',
      flexDirection: 'column',
    },

    intestazione: {
      paddingHorizontal: 30,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      paddingBottom: 20
    },
    box: {
      width: 135,
      height: 50,
    },
    boxAllegato: {
      border: '1px solid black',
      padding: 5,
      width: 135,
      alignItems: 'center'
    },

    logoSection: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 3,
      width: '100%'
    },

    contetPDF: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 30,
      fontSize: 10,
      fontWeight: 'normal',
      fontStyle: 'normal',
    },

    title: {
      fontSize: 12,
      fontWeight: 800,
      fontStyle: 'normal',

    },

    textArea: {
      flexWrap: 'wrap',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      paddingRight: '10',
      lineHeight: 1.5,
    },

    editableSpce: {
      lineHeight: 0.2,
      marginHorizontal: 3,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },

    textSmall: {
      fontSize: 7,
      fontWeight: 300,
      textAlign: 'center'
    },
    textMedium: {
      fontSize: 10,
    },
    textBig: {
      fontStyle: 'normal',
      fontSize: 20,
      fontWeight: 'bold',
    },
    centerContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    smallDivider: {
      width: '200px',
      border: '0.5px solid black',
    },
    sectionFirma: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start'
    },
    firma: {
      height: 50
    }
  });

  let professions = profile.userDomainAttributes.jobInfo?.professions || [];
  let jobTitles = [];
  let discipline = [];
  professions.forEach(profession => {
    jobTitles.push(replaceAsciiWithChar(profession.professionTitle));
    discipline = [...discipline, ...profession.discipline.map(disciplina => replaceAsciiWithChar(disciplina.disciplineTitle))];
  })
  const jobTitleStr = jobTitles.join('; ');
  const disciplineStr = discipline.join('; ');

  const getRoleTextByCode = (code)=>{
    const ROLES = {
      P: "Partecipante",
      D: "Docente",
      R: "Relatore",
      S: "Responsabile scientifico",
      M: "Moderatore",
      T: "Tutor"
    }
    return ROLES[code] || "";
  }
  const certificateData = {
    logoAccreditante: packageInfo.ecmLogoEnte || "",
    denominazioneEnteAccreditante: replaceAsciiWithChar(packageInfo.ecmDenominazioneEnte) || "",
    logoProvider: packageInfo.ecmLogoProvider || "",
    denominazioneProvider: replaceAsciiWithChar(packageInfo.ecmDenominazioneProvider) || "",
    nomeCognomeRappresentanteLegale: replaceAsciiWithChar(packageInfo.ecmRappresentanteLegale) || "",
    firmaRappresentanteLegale: packageInfo.ecmFirmaRappresentanteLegale || "",
    idEvento: packageInfo.ecmEventId || "",
    idProvider: packageInfo.ecmOrganizerCode || "", // organizer_code
    nEdizione: packageInfo.ecmEditionCode || "", // edition_code
    titoloEvento: replaceAsciiWithChar(packageInfo.title) || "",
    luogoSvolgimentoEvento: replaceAsciiWithChar(packageInfo.ecmCity) || "",
    dataInizioEvento: packageInfo.ecmStartDate ? new Date(packageInfo.ecmStartDate).toLocaleDateString() : '',
    dataFineEvento: packageInfo.ecmEndDate ? new Date(packageInfo.ecmEndDate).toLocaleDateString() : '',
    obiettivoFormativo: packageInfo.ecmTargetCode?.map(target => replaceAsciiWithChar(target.name)).join('; ') || "", //"(n° e/o descrizione)",
    nomeFruitore: replaceAsciiWithChar(profile.firstname) || "",
    cognomeFruitore: replaceAsciiWithChar(profile.lastname) || "",
    codiceFiscale: profile?.fiscalCode || company?.orgfiscalcode, // userinfo
    professionePartecipante: jobTitleStr, // userinfo
    disciplinaPartecipante: disciplineStr, // userinfo
    ruoloDiPartecipazione: getRoleTextByCode(packageInfo.ecmParticipationRole) || "", // product-catalog
    dataAcquisizioneCrediti: packageInfo.ecmEndDate ? new Date(packageInfo.ecmEndDate).toLocaleDateString() : '', // product-catalog // la data di acquisizione crediti corrisponde alla data di fine edizione (campo a livello di pacchetto)
    creditiEcmAcquisiti: ecmCreditiAcquisiti || "", // product-catalog // verificare se si può tradurre in lettere
    partecipanteReclutato: packageInfo.ecmSponsoredUser === "SI",
    dataCreazioneAttestato: new Date().toLocaleDateString('en-GB'),
    luogoCreazioneAttestato: "Milano"
  }
  // la data di conclusione dell'evento (se si intende evento live, quindi una classe fisica/virtuale) è riportata esclusivamente nell'oggetto sessione legato al live event



  useEffect(()=>{
    if (assetDetail.selection?.items && assetDetail.selection?.items.length && getEcmCreditiAcquisiti){
      let userEventCode = assetDetail.selection?.items[0].ecmId;
      getEcmCreditiAcquisiti(userEventCode)
    }
  },[assetDetail,getEcmCreditiAcquisiti])

  const Attestato = () => (
      <Document>
      <Page size="A4" style={stylespdfAttestato.page} orientation={'landscape'}>
        <ImagePdf style={stylespdfAttestato.bgImg} src={SfondoPDF} />
        <View style={stylespdfAttestato.section}>
          <View style={stylespdfAttestato.redLine}></View>
          <TextPdf style={stylespdfAttestato.attestatoLine1}>Attestato</TextPdf>
          <TextPdf style={stylespdfAttestato.attestatoLine2}>di partecipazione</TextPdf>
          <TextPdf style={stylespdfAttestato.textRegular}>Si attesta che il Dr./Prof.</TextPdf>
          <TextPdf style={stylespdfAttestato.nomeCognome}>{profile.firstname} {profile.lastname}</TextPdf>

          <TextPdf style={stylespdfAttestato.textRegular}>Ha partecipato al</TextPdf>
          <TextPdf style={stylespdfAttestato.nomeCorso}>{packageInfo.title}</TextPdf>
          <TextPdf style={stylespdfAttestato.textRegular}>tenutosi sulla piattaforma Digit'EdMed</TextPdf>

          <View style={stylespdfAttestato.sectionLeadershipContainer}>
            <View style={stylespdfAttestato.sectionLeadership}>
              <TextPdf style={stylespdfAttestato.textSmall}>Data:</TextPdf>
              <TextPdf style={stylespdfAttestato.textDate}>{new Date().toLocaleDateString('en-GB')}</TextPdf>
            </View>
            <View style={stylespdfAttestato.sectionFirma}>
              <TextPdf style={stylespdfAttestato.textSmall}>Patrizia Angelotti</TextPdf>
              {packageInfo.ecmFirmaRappresentanteLegale && <ImagePdf style={stylespdfAttestato.firma} src={packageInfo.ecmFirmaRappresentanteLegale} />}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  const Certificato = ({ isFad = true }) => (
      <Document>
      <Page size="A4" rientation={'FOLIO'}>
        <View style={stylespdfCertificato.container}>
          <View style={stylespdfCertificato.intestazione}>
            <View style={stylespdfCertificato.boxAllegato}>
              <TextPdf style={stylespdfCertificato.textBig}>ALLEGATO C</TextPdf>
            </View>
            <View style={stylespdfCertificato.logoSection}>
              <View style={stylespdfCertificato.box} >
                <ImagePdf src={certificateData.logoAccreditante} />
              </View>
              <View style={stylespdfCertificato.box} >
                <ImagePdf src={certificateData.logoProvider} />
              </View>
            </View>
          </View>

          <View style={stylespdfCertificato.contetPDF}>
            <TextPdf style={[stylespdfCertificato.title, { marginBottom: 10, textAlign: 'center' }]}>
              Programma per la formazione continua dei professionisti della Sanità
            </TextPdf>
            <View style={stylespdfCertificato.textArea}>
              <TextPdf >
                Premesso che
              </TextPdf>
              <View style={stylespdfCertificato.editableSpce}>
                <TextPdf >
                  {certificateData.denominazioneEnteAccreditante}
                </TextPdf>
                <TextPdf >
                  ………………………………………………………
                </TextPdf>
              </View>
              <TextPdf >
                ha accreditato il Provider
              </TextPdf>
              <View style={stylespdfCertificato.editableSpce}>
                <TextPdf >
                  {certificateData.denominazioneProvider}
                </TextPdf>
                <TextPdf >
                  …………………………………………………………………
                </TextPdf>
              </View>

              <TextPdf>accreditamento n.</TextPdf>
              <View style={stylespdfCertificato.editableSpce}>
                <TextPdf >
                  {certificateData.idProvider}
                </TextPdf>
                <TextPdf >
                  …………………………………………………………………
                </TextPdf>
              </View>
            </View>
            <View style={[stylespdfCertificato.textArea, { marginTop: 10 }]}>
              <TextPdf >
                Premesso che il Provider ha organizzato l’evento formativo n.
              </TextPdf>
              <View style={stylespdfCertificato.editableSpce}>
                <TextPdf >
                  {certificateData.idEvento}
                </TextPdf>
                <TextPdf >
                  ……………,
                </TextPdf>
              </View>
              <TextPdf>
                edizione n.
              </TextPdf>
              <View style={stylespdfCertificato.editableSpce}>
                <TextPdf >
                  {certificateData.nEdizione}
                </TextPdf>
                <TextPdf >
                  ……………,
                </TextPdf>
              </View>
              <TextPdf>e tenutosi </TextPdf>
              {(isFad && <>
                <TextPdf>{"a"}</TextPdf>
                <View style={stylespdfCertificato.editableSpce}>
                  <TextPdf >
                    {certificateData.luogoSvolgimentoEvento}
                  </TextPdf>
                  <TextPdf >
                    ……………………………
                  </TextPdf>
                </View>
              </>)}
              <TextPdf>
                dal
              </TextPdf>

              <View style={stylespdfCertificato.editableSpce}>
                <TextPdf >
                  {certificateData.dataInizioEvento}
                </TextPdf>
                <TextPdf >
                  ………………………
                </TextPdf>
              </View>
              <TextPdf>
                al
              </TextPdf>
              <View style={stylespdfCertificato.editableSpce}>
                <TextPdf >
                  {certificateData.dataFineEvento}
                </TextPdf>
                <TextPdf >
                  ………………………,
                </TextPdf>
              </View>
              <TextPdf>
                avente come obiettivo formativo
              </TextPdf>
              <TextPdf style={{borderBottom: '1px dotted black'}}>
                {certificateData.obiettivoFormativo}
              </TextPdf>
              {/*<View style={stylespdfCertificato.editableSpce}>
                <TextPdf >
                  {certificateData.obiettivoFormativo}
                </TextPdf>
                <TextPdf >
                  ……………………………………………………………………
                </TextPdf>
              </View>*/}
            </View>
            <TextPdf style={{ marginVertical: 15, textAlign: 'center' }} >
              il/la sottoscritto/a
            </TextPdf>
            <View style={stylespdfCertificato.editableSpce}>
              <TextPdf >
                {certificateData.nomeCognomeRappresentanteLegale}
              </TextPdf>
              <TextPdf >
                ……………………………………………………………………………………………………………………………
              </TextPdf>
            </View>
            <View style={[stylespdfCertificato.centerContent, { flexDirection: 'column', marginTop: 20 }]}>
              <TextPdf>
                Rappresentante legale del Provider
              </TextPdf>
              {certificateData.firmaRappresentanteLegale && <ImagePdf style={stylespdfCertificato.firma} src={certificateData.firmaRappresentanteLegale} />}
              </View>
            <View style={[stylespdfCertificato.centerContent, { flexDirection: 'column', marginTop: 30 }]}>
              <TextPdf style={stylespdfCertificato.textBig}>
                ATTESTA
              </TextPdf>
              <TextPdf>che il/la </TextPdf>
            </View>
            <View style={[stylespdfCertificato.textArea, { marginTop: 20 }]}>
              <TextPdf>Prof./Prof.ssa Dott./Dott.ssa Sig./Sig.ra</TextPdf>
              <View style={stylespdfCertificato.editableSpce}>
                <TextPdf >
                  {certificateData.nomeFruitore} {certificateData.cognomeFruitore}
                </TextPdf>
                <TextPdf >
                  ……………………………………
                </TextPdf>
              </View>
              <TextPdf>
                C.F.
              </TextPdf>
              <View style={stylespdfCertificato.editableSpce}>
                <TextPdf >
                  {certificateData.codiceFiscale}
                </TextPdf>
                <TextPdf >
                  ……………………
                </TextPdf>
              </View>
              <TextPdf>in qualità di </TextPdf>
              <View style={stylespdfCertificato.editableSpce}>
                <TextPdf >
                  {certificateData.ruoloDiPartecipazione}
                </TextPdf>
                <TextPdf >
                  ………………………………………………………………
                </TextPdf>
              </View>
              <TextPdf>il</TextPdf>
              <View style={stylespdfCertificato.editableSpce}>
                <TextPdf >
                  {certificateData.dataAcquisizioneCrediti}
                </TextPdf>
                <TextPdf >
                  ……………………
                </TextPdf>
              </View>
              <TextPdf>come</TextPdf>
              <View style={stylespdfCertificato.editableSpce}>
                <TextPdf >
                  {certificateData.partecipanteReclutato ? 'partecipante reclutato' : 'partecipante non reclutato'}
                </TextPdf>
                <TextPdf >
                  ………………………………………………………………
                </TextPdf>
              </View>
              <TextPdf> ha acquisito: </TextPdf>
            </View>
            <View style={[stylespdfCertificato.centerContent, { flexDirection: 'column', marginTop: 30 }]}>
              <View style={[stylespdfCertificato.textArea, { width: 300 }]}>
                <View style={stylespdfCertificato.editableSpce}>
                  <TextPdf style={{ textAlign: 'center' }} >
                    {certificateData.creditiEcmAcquisiti}
                  </TextPdf>
                  <TextPdf >
                    ……………………………
                  </TextPdf>
                </View>
                <TextPdf style={{ fontWeight: 800 }}>
                  Crediti formativi E.C.M.
                </TextPdf>
                <TextPdf style={stylespdfCertificato.textSmall}>{"(secondo i parametri stabiliti dai 'Criteri per l’assegnazione dei crediti alle attività ECM' allegati all’Accordo Stato Regioni del 02/02/2017)"}</TextPdf>
              </View>
            </View>
            <View style={[stylespdfCertificato.textArea, { marginTop: 20 }]}>
              <TextPdf> nella professione</TextPdf>
              <View style={stylespdfCertificato.editableSpce}>
                <TextPdf>{certificateData.professionePartecipante}</TextPdf>
                <TextPdf>
                  ……………………………………
                </TextPdf>
              </View>
              <TextPdf>disciplina </TextPdf>
              <TextPdf style={{borderBottom: '1px dotted black'}}>{certificateData.disciplinaPartecipante}</TextPdf>

            </View>
            <View style={[stylespdfCertificato.textArea, { marginTop: 50 }]}>
              <View style={stylespdfCertificato.editableSpce}>
                <TextPdf>{certificateData.luogoCreazioneAttestato}</TextPdf>
                <TextPdf>
                  ……………………………,
                </TextPdf>
              </View>
              <TextPdf> il</TextPdf>
              <View style={stylespdfCertificato.editableSpce}>
                <TextPdf>{certificateData.dataCreazioneAttestato}</TextPdf>
                <TextPdf>
                  ……………………………
                </TextPdf>
          </View>

            </View>
            <View style={[stylespdfCertificato.centerContent, { flexDirection: 'column', marginTop: 20 }]}>
              <TextPdf>
                Il RAPPRESENTANTE LEGALE
              </TextPdf>
              <TextPdf style={{ width: 300, textAlign: 'center' }}>
                {"(o suo delegato ovvero Responsabile scientifico dell’evento, su delega del rappresentante legale del Provider)"}
              </TextPdf>
            </View>
            <View style={stylespdfCertificato.sectionFirma}>
              {certificateData.firmaRappresentanteLegale && <ImagePdf style={stylespdfCertificato.firma} src={certificateData.firmaRappresentanteLegale} />}
              </View>
            <View style={stylespdfCertificato.smallDivider}>
            </View>
          </View>
          </View>
        </Page>
      </Document>
  );

  if (Object.keys(packageInfo) < 1) return <Spinner />;

  const getCertificatoSection = ()=>{

    return <Container>
      <PackageFooterCard bg={packageInfo.cover ?? PlaceholderImg}>
        <Row>
          <Col xs={12} md={12}>
            <Title>
              <Text>
                <ProDisplay
                    type="5"
                    configuration={{
                      fontSize: 40,
                      lineHeight: 1.2
                    }}
                >
                  {/* solo il partecipante vedrà il certificato */}
                  {(packageInfo.ecmFlag && (packageInfo.ecmParticipationRole === 'P')) ? (packageInfo.surveyCompleted ? 'Scarica il tuo certificato' : 'Compila la survey di gradimento e scarica il tuo certificato') : 'Compila la survey di gradimento'}
                </ProDisplay>
              </Text>
              {packageInfo.ecmFlag && packageInfo.ecmCertificateEnabled && packageInfo.surveyCompleted && <Text><ProDisplay
                  type="5"
                  configuration={{
                    fontSize: 22,
                    lineHeight: 1.2
                  }}
              >
                Il certificato ottenuto verrà reso disponibile entro 90 giorni sul sito www.agenas.it
              </ProDisplay></Text>}
            </Title>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12}>
            {!packageInfo.surveyCompleted ? (
                <>
                  {!packageInfo.surveyEnabled ? (
                      getSurvey()
                  ) : <>
                        <AssessmentModal
                            itemId={packageInfo.itemId}
                            surveyId={packageInfo.surveyId}
                            packageId={id}
                            playlist={packageInfo}
                            playlistId={packageInfo.itemId}
                            type={'package'}
                            ecmFlag={packageInfo.ecmFlag}
                        />
                        <SurveyContainer className={'package'}>
                          <Text>
                            <ProText
                                type="detailAction"
                                configuration={{ color: 'white' }}
                            >
                              Completa la survey di gradimento
                            </ProText>
                          </Text>
                          <ButtonWrapper>
                            <Button
                                type="PRIMARY"
                                text="INIZIA"
                                height={35}
                                fontSize={10}
                                onClick={() => openModal(AssessmentModal.id)}
                            />
                          </ButtonWrapper>
                        </SurveyContainer>
                      </>
                  }
                </>
            ) : packageInfo.ecmCertificateEnabled && <PDFDownloadLink
                style={{ color: 'transparent' }}
                document={<Certificato isFad={false} />}
                fileName="Certificato.pdf"
            >
              <IconBigDownload />
            </PDFDownloadLink>}

          </Col>
        </Row>
      </PackageFooterCard>
    </Container>
  }

  const getAttestatoSection = ()=>{
    return <Container>
      <PackageFooterCard bg={packageInfo.cover ?? PlaceholderImg}>
        <Row>
          <Col xs={12} md={8}>
            <Title>
              <Text>
                <ProDisplay
                    type="5"
                    configuration={{
                      fontSize: 40,
                      lineHeight: 1.2
                    }}
                >
                  {'Scarica il tuo attestato'}
                </ProDisplay>
              </Text>
            </Title>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            {
                <PDFDownloadLink
                    style={{color: 'transparent'}}
                    document={<Attestato/>}
                    fileName="AttestatoDiPartecipazione.pdf"
                >
                  <IconBigDownload/>
                </PDFDownloadLink>
            }
          </Col>
        </Row>
      </PackageFooterCard>
    </Container>
  }

  const getSurvey = ()=>{
    return <>
      {packageInfo.packageType === 'premium' ?
          <ProText>La Survey sarà disponibile ad completamento dell'<b>80% del
                                                                       journey</b></ProText> :
          <ProText>La Survey sarà disponibile ad completamento del <b>100% del
                                                                      journey</b></ProText>
      }
      <PackageProgresss
          progress={
            packageInfo.numberOfAssetContentsCompleted ?
                (packageInfo.numberOfAssetContentsCompleted / packageInfo.numberOfAssetContents)
                : 0
          }
          className='mt-4'
      >
        <span className='icon-lock-reverse'></span>
        <span className="bar bg ml-4">
                              <span className="bar fg"></span>
                            </span>
      </PackageProgresss>
    </>
  }


  return (
      <>
        <MainContainer>
          <HeaderWrapper>
            <FirstHalfWrapper>
              <Container>
                <FirstHalf>
                  <HeaderIcon
                      className="icon-arrow-right first"
                      onClick={() => pushUrl(routes.homepage.path)}
                  />
                  <Title id="playlist-title">
                    <Text>
                      <ProText
                          type="playlistOvertitle"
                          configuration={{ color: 'white' }}
                      >
                        Pacchetto
                      </ProText>
                    </Text>
                    <Text className="flex-start">
                      <ProText
                          type="playlistTitle"
                          configuration={{ color: 'white' }}
                      >
                        {packageInfo.title}
                      </ProText>
                      {/*{packageInfo.body ? (*/}
                      {/*    <Tooltip text={packageInfo.body}>*/}
                      {/*      <IconInfo className="icon-uniE940" />*/}
                      {/*    </Tooltip>*/}
                      {/*) : null}*/}
                    </Text>
                  </Title>
                </FirstHalf>
              </Container>
            </FirstHalfWrapper>
          </HeaderWrapper>
          <PackageHeader bg={packageInfo.cover ?? PlaceholderImg} className={'py-5 py-lg-4'}>
            <Container>
              {/* in linea con box in dettaglio evento */}
              <ProDisplay
                  type={TYPES.DISPLAY.PACKAGE_DETAIL_TITLE}
                  currentViewport={currentViewport}
              >
                {packageInfo.title}
              </ProDisplay>
              {/*<ProText*/}
              {/*    type={TYPES.TEXT.PACKAGE_DETAIL_SUBTITLE}*/}
              {/*    currentViewport={currentViewport}*/}
              {/*    className={'mt-3'}*/}
              {/*>*/}
              {/*  {packageInfo.body}*/}
              {/*</ProText>*/}
              <ProText
                  type={TYPES.TEXT.PACKAGE_DETAIL_HERO}
                  currentViewport={currentViewport}
                  className={'mt-5'}
              >
                Durata: <b>{secsToTime(packageInfo.totalDuration)}</b>
              </ProText>
              <PackageProgresss
                  progress={
                    packageInfo.numberOfAssetContentsCompleted ?
                        (packageInfo.numberOfAssetContentsCompleted / packageInfo.numberOfAssetContents)
                        : 0
                  }
                  className='mb-4'
              >
                <ProText
                    type={TYPES.TEXT.PACKAGE_DETAIL_HERO}
                    currentViewport={currentViewport}
                >
                  Avanzamento: <span className='bold'>{
                  packageInfo.numberOfAssetContentsCompleted ?
                      Math.round((packageInfo.numberOfAssetContentsCompleted / packageInfo.numberOfAssetContents) * 100)
                      : 0}%</span>
                </ProText>
                <span className="bar bg ml-4">
                  <span className="bar fg"></span>
                </span>
              </PackageProgresss>
            </Container>
          </PackageHeader>
          <Container>
            <Carousel
                swipeable
                responsive={responsiveLargeCards}
                draggable={viewportTypes[currentViewport] < viewportTypes.mediaIsLaptop}
                customRightArrow={<CustomRightArrow offset={'20px'}/>}
                customLeftArrow={<CustomLeftArrow offset={'20px'} />}
                containerClass="react-multi-carousel-list container pl-0 py-4 mt-5 mb-4"
            >
              {contents?.length > 0 &&
              contents.map((card, index) => (
                  <ContentCardUser
                      homepage
                      key={card.itemId ?? card.packageId}
                      type={card.type}
                      ellipsis={1}
                      data={card}
                      openModalInfo={(card, type)=>{openModalInfoHandler(card, type)}}
                      onClick={() => openDetails(card)}
                      index={index}
                  />
              ))}
            </Carousel>
          </Container>
          { ((packageInfo?.links && packageInfo.links[0].linkUri !== null) || packageInfo?.documents.length > 0) &&
          <Section className='gray-bg'>
            <Container>
                  <ProDisplay
                      type="5"
                      configuration={{
                        fontSize: 20,
                            lineHeight: 1.86,
                            color: '#323232'
                      }}
                  >
                    {'Per approfondire'}
                  </ProDisplay>
                  <Row className='link-doc-row'>
                    {(packageInfo?.links && packageInfo.links[0].linkUri!== null) ?
                        <Col xs={12} sm={6} className="link-doc-row__links">
                          {
                            packageInfo.links.map((item, idx) => {
                              const linkName = item.linkName ? unescape(item.linkName) : '';
                              return (
                                  <div key={`${linkName}-${idx}`} className='item'>
                                    <span className='icon icon-icona-allega'></span>
                                    <div className='value'>
                                      <Link href={item.linkUri} target="_blank">
                                        <ProText
                                            type="playlistTitle"
                                            configuration={{ fontWeight: 'bold' }}
                                        >
                                          {linkName}
                                        </ProText>
                                      </Link>
                                    </div>
                                  </div>
                              )
                            })
                          }
                        </Col>
                        : null}
                    <Col xs={12} sm={(packageInfo?.links && packageInfo.links[0].linkUri !== null) ? 6 : 12} className="link-doc-row__docs">
                      {
                        packageInfo.documents.map((item, idx) => {
                              const fileName = item.fileName ? unescape(item.fileName) : `file-${idx}`;
                          return (
                              <div key={`${fileName}-${idx}`} className='item'>
                          <span className='icon'>
                              {item.fileExtension === 'pdf' ? <Pdf/>
                                  : item.fileExtension === 'xls' || item.fileExtension === 'xlsx' || item.fileExtension === 'csv'  ? <Excel/>
                                      : item.fileExtension === 'doc' || item.fileExtension === 'docx' ? <Word/>
                                          : item.fileExtension === 'ppt' || item.fileExtension === 'pptx' ? <Powerpoint/>
                                              : <span className='icon-icona-allega'></span>}
                          </span>
                                <div className='value'>
                                  <Link href={item.fileUri} target="_blank">
                                    <ProText
                                        type="playlistTitle"
                                        configuration={{ fontWeight: 'bold' }}
                                    >
                                      {fileName}
                                    </ProText>
                                  </Link>
                                </div>
                                <Link href={item.fileUri} target="_blank" className='icon-download-link'>
                                  <span className='icon-download'></span>
                                </Link>
                              </div>
                          )
                        })
                      }
                    </Col>
                  </Row>
            </Container>
          </Section>
          }
        {/*Da mostrare solo al completamento 100% compreso test finale*/}

          {/* ecm: attestato visibile quando parametro ecmAttestationEnabled è true */}
          {((packageInfo.ecmFlag && packageInfo.ecmAttestationEnabled) ||
                  /* non ecm: attestato visibile completato 100% tranne test finale */
                  (packageInfo.numberOfAsset === packageInfo.numberOfAssetCompleted))
              && getAttestatoSection()}
          {packageInfo.surveyId !== null ?
              /* per ecm sezione certificato/survey visibile sempre. Se non ecm non c'è certificato, quindi sezione visibile solo se non ho già completato la survey */
              ((packageInfo.ecmFlag && packageInfo.ecmCertificateEnabled) || !packageInfo.surveyCompleted) && getCertificatoSection()
              : '' }
          <InfoModal />
        </MainContainer>
        <Footer />
      </>
  );
};

PackageDetails.defaultProps = {
  packageSelected: {},
};

  PackageDetails.propTypes = {
    // HOC (connect, state)
    assetDetail: PropTypes.object,
    packageInfo: PropTypes.object,
    profile: PropTypes.object,
    company: PropTypes.object,
    router: PropTypes.object,

    // HOC (connect, dispatch)
    getPackage: PropTypes.func,
    openModal: PropTypes.func,
    pushUrl: PropTypes.func,
    setPlaylistHistory: PropTypes.func,
    setSection: PropTypes.func,
    getPackageAssetDescription: PropTypes.func,
  };

export default withMediaQueries(
    connect(
      state => {
        const { user: profile = {} } = state.authentication;
        const { company } = state.profile;
        const { packageSelected: { packageInfo, assetDetail } } = state.playlist.packages;
      const { packageSelected: packageData } = state.playlist.packages;
        const { router } = state;
      const { id: domainId } = state.domain;
      const { ecmCreditiAcquisiti } = state.ecm;
        return {
          assetDetail,
          packageInfo,
          profile,
          router,
        packageData,
        domainId,
          ecmCreditiAcquisiti,
          company,
        };
      },
      dispatch => ({
        getPackage: itemId => dispatch({ type: GET_PACKAGE._REQUEST, itemId }),
        openModal: id => dispatch({ type: MODAL_OPEN, id }),
        pushUrl: url => dispatch(push(url)),
        setPlaylistHistory: source => dispatch({ type: SET_PLAYLIST_HISTORY, source }),
        setSection: section => dispatch({ type: SET_SECTION, section }),
        getPackageAssetDescription: payload => dispatch({ type: GET_PACKAGE_ASSET_DESCRIPTION._REQUEST, payload }),
        getEcmCreditiAcquisiti: userEventCode => dispatch({ type: GET_ECM_CREDITI_ACQUISITI._REQUEST, userEventCode })
      })
    )(PackageDetails)
);
